<template>
  <v-text-field
    :model-value="value"
    v-bind="bind"
    @update:focused="onFocusUpdate"
    @update:model-value="onInput"
  >
    <template #append-inner>
      <slot name="append-inner" />
    </template>
  </v-text-field>
</template>

<script lang="ts" setup>
const properties = defineProps({
  ...appFormInputProperties,
});

const emit = defineEmits<{ blur: [] }>();

const { value, bind } = useAppFormInput(properties);

function onFocusUpdate(focused: boolean) {
  if (focused) return;

  emit("blur");
}

function onInput(updated: string | undefined) {
  if (updated === undefined) {
    value.value = undefined as unknown as string;
    return;
  }

  if (properties.type === "number") {
    value.value = Number.parseInt(updated, 10);
    return;
  }

  value.value = updated;
}
</script>
